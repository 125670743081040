import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const Login = () => import('@/views/Login')

// ViewsCreateServiceOrder
const Dashboard = () => import('@/views/Dashboard')
const Settings = () => import('@/views/Settings')
const Users = () => import('@/views/Users')
const Versions = () => import('@/views/Versions')
const Analytics = () => import('@/views/Analytics')
const UnfinishedDeliveries = () => import('@/views/UnfinishedDeliveries')
const Surveys = () => import('@/views/Surveys')
const ShipTos = () => import('@/views/ShipTos')
const SafetyChecks = () => import('@/views/SafetyCheck')
const CompletedAtWork = () => import('@/views/CompletedAtWork')
const Answers = () => import('@/views/Answers')



Vue.use(Router)

function configRoutes() {
  return [
    { meta: { type: 'public' }, path: '/login', name: 'login', component: Login },
    {
      meta: { type: 'public' }, path: '/', redirect: '/dashboard', name: 'Home', component: TheContainer,

      children: [
        { meta: { type: 'public' }, path: 'dashboard', name: 'Dashboard', component: Dashboard },
        { meta: { type: 'public' }, path: 'settings', name: 'Country Settings', component: Settings },
        { meta: { type: 'public' }, path: 'users', name: 'Console Users', component: Users },
        { meta: { type: 'public' }, path: 'versions', name: 'Version Control', component: Versions },
        { meta: { type: 'public' }, path: 'analytics', name: 'Analytics', component: Analytics },
        { meta: { type: 'public' }, path: 'unfinisheddeliveries', name: 'Unfinished Load Lines', component: UnfinishedDeliveries },
        { meta: { type: 'public' }, path: 'surveys', name: 'Surveys', component: Surveys },
        { meta: { type: 'public' }, path: 'shipTos', name: 'ShipTos', component: ShipTos },
        { meta: { type: 'public' }, path: 'safetyChecks', name: 'Safety Checks', component: SafetyChecks },
        { meta: { type: 'public' }, path: 'completedAtWork', name: 'Completed At Work', component: CompletedAtWork },
        {
          meta: { type: 'public' }, path: 'surveys/:id', name: 'Surveys', component: Answers, children: [
            { path: 'answers', name: 'Answers' }
          ]
        }
      ]

    }
  ]
}




let router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  saveScrollPosition: true,
  history: true,
  force: true,
  routes: configRoutes()
})



router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("user");

  var isAuthenticated = false;
  try {
    isAuthenticated = (user !== undefined && user !== null);

  } catch (e) { console.log(e) }

  if (to.path != '/ConfigFile' && to.path != '/login') {
    if (Vue.prototype.ConfigFile) {
      console.log("configFile");
      next('/ConfigFile')
    } else if (!isAuthenticated) {

      next('/login')


    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
