import Vue from 'vue'
import jquery from 'jquery';

Vue.mixin({
    data() {
        return {
            notifications: {
                total: 0,
                users: 0
            }
        }
    },
    methods: {
        $$: jquery,
        getUser() {
            return new Promise((resolve, reject) => {
                var userSend = JSON.parse(localStorage.getItem("user"));
                resolve(userSend)
            })
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        // TODO: Ahora usaremos un endpoint del api, pero se podra implementar en un futuro con socketio
        async loadNotifications() {
            const self = this;
            try {
                const res = await self.$http.get("apiConsole/notification/getNotifications");
                self.notifications = res.data.notifications;


                // console.log(self.notifications)
            } catch (e) {
                console.log(e);
            }
        },
    },
})